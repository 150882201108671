import React from "react";
import PageWrapper from "../components/PageWrapper";
import HeroComponent from "../components/HeroComponent";
import LipsGlance from "../components/glance.js";
import Why from "../components/why.js";
import Accordion from "../components/Accordion.js";
import imgVideo from "../assets/image/jpeg/lip-filler-doctor.png";
import Testimonial from "../sections/index/indexreviews.js";
import Saveface from '../components/save.js';
import Trend from '../components/trends.js';
import Content from "../sections/profhilo/profcontent.js";
import Content2 from "../sections/profhilo/profgallery.js";
import LipsFaq from "../sections/profhilo/proffaq.js";
import Head from "../components/head";
import Clinic from '../components/NewFind.js';  
import Award from "../components/NewToxane.js";
import { JsonLd } from '../components/JsonLd';
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";

const ProfhiloPage = () => {
  const accordionItems = [
    { title: "What is Profhilo?", content: <Content /> },
    { title: "Profhilo before and after pictures", content: <Content2 /> },
    { title: "Why choose Dermamina", content:<Why />, isLast: true},
  ];
  return (
    <>
 <Head title="Profhilo Skin Boost London" image="/images/dermamina-home.jpg"  description="Experience skin rejuvenation with our advanced Profhilo treatment. Achieve natural, youthful glow. Discover the power of Profhilo today!" keywords="Profhilo treatment, Profhilo injections, Skin rejuvenation, anti-ageing treatment, Hyaluronic acid, Non-surgical facelift, Youthful skin, Collagen boosting, Skin hydration, Wrinkle reduction"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
'@type': 'ContactPoint',
telephone: '020 7099 6650',
contactType: 'Customer service',
},
address:{
'@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
{'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},


{'@type': 'ListItem', 
position:2,
name:'Profhilo London',
item:'https://www.dermamina.com/profhilo',
},

]
}}
</JsonLd>

<JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What areas can be treated by Profhilo?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Profhilo is particularly effective when treating the face, neck and decolletage, and hands. The face is the most commonly treated location, with 5 Bio Aesthetic Points (BAP) injected on each side to maximise diffusion in the cheeks and lower face to obtain the best results, minimise discomfort, and ensure safety."
            }
          },
          {
            "@type": "Question",
            "name": "Does Profhilo Hurt?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Patients usually find the level of discomfort to be minimum and very manageable. We understand that everyone's pain tolerance is different, and we are happy to provide anaesthetic cream to our patients before the treatment begins. There may be slight discomfort after the treatment, which is to be expected, but there should be no substantial pain. Post treatment advice will be addressed and a copy will be sent directly to your email, to ensure the patient is aware of what to expect and aftercare to follow."
            }
          },
          {
            "@type": "Question",
            "name": "DHow long does Profhilo work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Improvements will be noticed within 7-10 days after the treatment. After completing the course, final results will be seen after 3-4 weeks. Results last 6 months. 2 treatments are needed, spaced 1 month apart. To maintain the result, 1 session will be needed every 6 months."
            }
          },
          {
            "@type": "Question",
            "name": "Is the Profhilo Treatment safe?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes. Profhilo consists of a highly pure grade of hyaluronic acid that is exceedingly biocompatible. Did you know hyaluronic acid is already found in our skin, making the substance itself safe for treatment?"
            }
          }
        ]
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "600"
      }}
    </JsonLd>


    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "Profhilo",
        "provider": {
          "@type": "Organization",
          "name": "Dermamina",
          "url": "https://www.dermamina.com"
        },
        "areaServed": {
          "@type": "Place",
          "name": "London"
        },
        "description": "Profhilo Treatment London",
        "offers": {
          "@type": "Offer",
          "url": "https://www.dermamina.com/non-surgical-nose-job",
          "priceCurrency": "GBP",
          "price": "300",
          "eligibleRegion": {
            "@type": "Place",
            "name": "London"
          }
        }
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": "Profhilo - Bringing out the beauty in you",
        "description": "Video explaining what is Profhilo?",
        "thumbnailUrl": "https://www.dermamina.com/static/lip-filler-doctor-a1756675ac6ccea796c3ffff40cbb1f2.png",
        "uploadDate": "2024-05-30T17:00:00+01:00",
        "embedUrl": "https://youtu.be/WOMtfqK3vOw?si=oEdNdjFWwhCPiIaU",
        "contentUrl": "https://www.youtube.com/watch?v=WOMtfqK3vOw"
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
          {
            "@type": "ImageObject",
            "position": 1,
            "name": "Before and After Profhilo",
            "contentUrl": "https://www.dermamina.com/static/profhilo1-efdc278040faae69795f2627a69cbe34.jpg",
            "description": "Image showing hair condition before and after Profhilo treatment."
          },
          {
            "@type": "ImageObject",
            "position": 2,
            "name": "Before and After Profhilo",
            "contentUrl": "https://www.dermamina.com/static/profhilo2-7660946975bade8940ff5f5108c31f58.jpg",
            "description": "Image showing hair condition before and after Profhilo treatment."
          }
        ]
      }}
    </JsonLd>


<PageWrapper headerDark>
<HeroComponent
    itext='<span style="color: #1a1a1a;">Profhilo</span> <span style="color: #00aec7;">Skin Booster</span>'
    secondText="Bringing out the beauty in you"
    videoUrl="https://www.youtube.com/watch?v=WOMtfqK3vOw" // Specify your video URL here
    videoAlt="Skin Boosters Profhilo Video"
    imgVideo={imgVideo} // Specify your image URL here
  />

<Section py={0} className="pt-lg-5 pb-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
<LipsGlance
   best="2 treatments"
  bestalt="best profhilo skin booster therapy"
  resultstext="Up to 6 months"
  resultsalt="profhilo skin booster therapy results"
  costdata="£300"
  costalt="Profhilo Cost"
  timedata="20 Minutes"
  timealt="Profhilo Duration Time"
  workdata="Immediately"
  workalt="Profhilo downtime work"
  paindata="None / Mild"
  painalt="Profhilo Pain"
  />
 </Col>
 <Col className="d-lg-none d-xl-none" lg="6" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Accordion items={accordionItems} /></Col>
  </Row>
  </Container>
  </Section>
<Testimonial />
<Award />
<LipsFaq />    
<Clinic />   
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default ProfhiloPage;
